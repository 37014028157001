export const menuTabs = {
    home: { href: "/home", title: "Inicio", icon: "fas fa-home" },
    posts: { href: "/posts", title: "Comunicados", icon: "far fa-comment-dots" },
    activities: { href: "/activities", title: "Actividades", icon: "far fa-clipboard" },
    linksOfInterest: { href: "/links", title: "Links de interés", icon: "fas fa-link" },
    reports: { href: "/reports", title: "Reportes", icon: "fas fa-chart-pie" },
    assets: { href: "/assets", title: "Activos", icon: "fas fa-box" },
    organization: { href: "/people", title: "Organización", icon: "fas fa-users" },
    organizationChart: { href: "/org-chart", title: "Organigrama", icon: "fas fa-sitemap" },
    checklists: { href: "/checklists", title: "Checklists", icon: "fas fa-pen" },
    audiences: { href: "/audiences", title: "Audiencias", icon: "fas fa-pen" },
    workflows: { href: "/workflows", title: "Workflows", icon: "fas fa-project-diagram" },
    checklistsAdministration: {
        href: "/checklist-administration",
        title: "Administración de checklists",
        icon: "fas fa-pen",
    },
} satisfies Record<string, MenuTab>;

export type MenuTab = {
    href: string;
    title: string;
    icon: string;
};
