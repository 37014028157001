import { ErrorBoundary, onCleanup, onMount, ParentProps, Show } from "solid-js";
import { useLocale } from "../modules/i18n/context";
import errorIllustration from "../assets/imgs/error.webp";
import { NavbarLogoOnly } from "../modules/ui/components";
import { Capacitor } from "@capacitor/core";
import { H1, P } from "./typography";
import Accordion, { AccordionItem } from "../modules/ui/Accordion";
import SupportParagraph from "./SupportParagraph";
import { useWindowEventListener } from "./solidjs";
import { describeError } from "./errorHandling";

export default function GenericErrorBoundary(props: ParentProps) {
    useWindowEventListener("error", event => {
        console.log("Caught onerror with this error: ", event.error);
    });

    useWindowEventListener("unhandledrejection", event => {
        console.log("Caught unhandledrejection with this event reason: ", event.reason);
    });

    return (
        <ErrorBoundary fallback={error => <Fallback error={error} />}>
            {props.children}
        </ErrorBoundary>
    );
}

function Fallback(props: { error: unknown }) {
    useBackReloadsPrevPage();

    const [locale] = useLocale();
    const t = () => locale().utils.unknownError;

    onMount(() => showViteErrorOverlay(props.error));

    return (
        <>
            <NavbarLogoOnly reload />
            <main class="flex min-h-screen flex-col items-center bg-layout px-4 py-4 lg:py-8">
                <img src={errorIllustration} alt="" class="w-1/2 mb-4" />
                <div class="max-w-prose">
                    <H1>{t().title}</H1>
                    <P>{t().sorry}</P>
                    <P>{t().meanwhile}</P>
                    <MeantimeList />
                    <P>{t().tryLater}</P>
                    <SupportParagraph />
                    <P>{t().thanks}</P>
                    <TechnicalDetailsAccordion error={props.error} />
                </div>
            </main>
        </>
    );
}

function useBackReloadsPrevPage() {
    const reload = () => location.reload();
    onMount(() => window.addEventListener("popstate", reload));
    onCleanup(() => window.removeEventListener("popstate", reload));
}

/** Based on https://github.com/vitejs/vite/issues/2076. */
function showViteErrorOverlay(error: unknown): void {
    const ErrorOverlay = customElements.get("vite-error-overlay");
    // don't open outside vite environment
    if (!ErrorOverlay) return;
    console.log(error);
    const overlay = new ErrorOverlay(error);
    document.body.appendChild(overlay);
}

function MeantimeList() {
    const [locale] = useLocale();
    const t = () => locale().utils.unknownError;

    return (
        <ol>
            <li>
                <Refresh />
            </li>
            <li>
                <GoHome />
            </li>
            <Show when={Capacitor.isNativePlatform()}>
                <li>{t().restart}</li>
            </Show>
            <li>{t().clearAppData}</li>
        </ol>
    );
}

function Refresh() {
    const [locale] = useLocale();
    const t = () => locale().utils.unknownError;

    return (
        <span>
            <button onClick={() => location.reload()} class="text-primary-500">
                {Capacitor.isNativePlatform() ? t().refreshScreen : t().refreshPage}
            </button>
            .
        </span>
    );
}

function GoHome() {
    const [locale] = useLocale();
    const t = () => locale().utils.unknownError;

    return (
        <span>
            <a href="/" class="text-primary-500">
                {t().goHome}
            </a>
            .
        </span>
    );
}

function TechnicalDetailsAccordion(props: { error: unknown }) {
    const [locale] = useLocale();

    return (
        <Accordion>
            <AccordionItem
                summary={locale().utils.showTechnicalDetails}
                summaryOpen={locale().utils.hideTechnicalDetails}
            >
                <P class="font-mono">{describeError(props.error)}</P>
                <Show when={props.error instanceof Error && props.error}>
                    {error => <P class="whitespace-pre font-mono">{error().stack}</P>}
                </Show>
            </AccordionItem>
        </Accordion>
    );
}

export function ErrorBlock(props: { error: unknown }) {
    const [locale] = useLocale();

    return (
        <div>
            <img src={errorIllustration} alt="" class="w-1/2 mb-4" />
            <P>{locale().utils.unknownError.title}</P>
            <TechnicalDetailsAccordion error={props.error} />
        </div>
    );
}
