import { Badge, Icon } from "../ui/components";
import { UnreadNotifications } from "./SemiNavbar";
import { createSignal, ParentProps, Show } from "solid-js";
import SlidingMenu, { Overlay } from "./SlidingMenu";
import logo from "../../assets/imgs/logo-gradient-default.svg";
import { useLocale } from "../i18n/context";
import MobileFooter from "./MobileFooter";
import Avatar from "../auth/Avatar";
import LogoutButton from "../auth/LogoutButton";
import VersionNumber from "../../utils/VersionNumber";
import { A } from "@solidjs/router";
import SettingsTab from "../settings/SettingsTab";
import { OnClickButton } from "../../utils/solidjs";
import CurrentOrganizationLogo from "../auth/CurrentOrganizationLogo";
import { useAuth } from "../auth/authContext";
import { parsedEnv } from "../../utils/parsedEnv";
import { SearchBar } from "../ui/SearchBar";
import { menuTabs } from "./utils";
import { createPermissionQuery } from "../../api/services/permissions/queries";
import _ from "lodash";
import { createMyRolesQuery } from "../../api/services/auth/queries";
import { Role } from "../../api/services/auth/interface";
import { createMyWorkflowTypesQuery } from "../../api/services/workflow/workflow-types/queries";

export default function MobileLayout(props: ParentProps) {
    const [menuOpen, setMenuOpen] = createSignal(false);
    const siteConfig = createPermissionQuery();
    const roles = createMyRolesQuery();
    const myWorkflowTypesQuery = createMyWorkflowTypesQuery();

    return (
        <div class="flex h-full flex-col bg-layout">
            <NavbarMobile onOpenMenu={() => setMenuOpen(true)} />
            <div class="relative h-full w-full bg-inherit">
                <div class="absolute inset-0 flex flex-col bg-inherit">
                    <div class="min-h-full overflow-y-auto bg-inherit">{props.children}</div>
                </div>
            </div>
            <MobileFooter
                tabs={_.compact([
                    siteConfig.data?.canReadHomeMenu && menuTabs.home,
                    ...(myWorkflowTypesQuery.data ?? []).map(wfType => ({
                        href: `/workflows/${wfType.id}/executions`,
                        title: wfType.name,
                        icon: wfType.icon,
                    })),
                    menuTabs.activities,
                    menuTabs.reports,
                    roles.data?.includes(Role.ADMIN) && {
                        href: "/workflows",
                        title: "Admin. tipos de workflows",
                        icon: "fas fa-project-diagram",
                    },
                ])}
            />
            <MobileMenu open={menuOpen()} onDismiss={() => setMenuOpen(false)} />
        </div>
    );
}

function MobileMenu(props: { open: boolean; onDismiss: () => void }) {
    return (
        <Overlay collapsed={!props.open} onClick={props.onDismiss} animated>
            <SlidingMenu
                collapsed={!props.open}
                navigationDrawerWidth="calc(100vw - 4.25rem)"
                onClick={event => event.stopPropagation()}
            >
                <MobileMenuContent onClose={props.onDismiss} />
            </SlidingMenu>
        </Overlay>
    );
}

function MobileMenuContent(props: { onClose: () => void }) {
    return (
        <nav class="flex h-full flex-col bg-white">
            <div class="sticky left-0 right-0 top-0 flex items-center border-b border-b-light-gray-300 py-2 ps-3">
                <img src={logo} class="h-8" alt="" />
                <div class="flex-1" />
                <SettingsTab onClick={props.onClose} />
                <CloseButton onClick={props.onClose} />
            </div>
            <div class="flex flex-1 flex-col gap-3 px-3 py-3">Lorem menu dolor sit amet.</div>
            <div class="sticky bottom-0 left-0 right-0 flex items-center border-t border-t-light-gray-300 bg-white py-1 pe-3">
                <LogoutButton />
                <div class="flex-1" />
                <VersionNumber />
            </div>
        </nav>
    );
}

function CloseButton(props: { onClick?: OnClickButton }) {
    return (
        <button class="center-items h-16 w-16" onClick={e => props.onClick?.(e)}>
            <i class="fas fa-times text-dark-gray-400" />
        </button>
    );
}

function NavbarMobile(props: { onOpenMenu: () => void }) {
    const { user } = useAuth();
    const [searchOpen, setSearchOpen] = createSignal(false);
    const toggleSearch = () => setSearchOpen(x => !x);
    const bgEnvNavColor = parsedEnv.VITE_COLOR ?? "inherit";
    const badgeEnvText = parsedEnv.VITE_BADGE;

    return (
        <nav
            class="sticky left-0 right-0 top-0 z-navbar bg-white shadow-light-gray-200"
            style={{ "border-top": `10px solid ${bgEnvNavColor}` }}
        >
            <div class="flex items-center py-1">
                <Show
                    when={parsedEnv.VITE_TASK_MANAGER_HOME_ENABLED}
                    fallback={
                        <>
                            <BurgerMobile onClick={props.onOpenMenu} />
                            <div class="flex-1">
                                <SearchBar variant="gray" />
                            </div>
                        </>
                    }
                >
                    <div class="flex flex-1 items-center ps-2">
                        <CurrentOrganizationLogo />
                        <Show when={badgeEnvText}>
                            <div class={"ml-2 self-center"}>
                                <Badge class={"!bg-dark-gray-700 text-white"}>{badgeEnvText}</Badge>
                            </div>
                        </Show>
                    </div>
                    <SearchButton onClick={toggleSearch} />
                </Show>

                {/*<SyncTab />*/}
                <NotificationsTab />
                <A href="/profile" class="center-items h-16 w-16">
                    <Avatar picture={user()?.picture} size="md" />
                </A>
            </div>
            <Show when={searchOpen()}>
                <div class="px-3 pb-2">
                    <SearchBar variant="white" autofocus />
                </div>
            </Show>
        </nav>
    );
}

function BurgerMobile(props: { onClick?: OnClickButton }) {
    return (
        <button class="px-3 py-2" onClick={e => props.onClick?.(e)}>
            <Icon name="fas fa-bars text-default" />
        </button>
    );
}

function SearchButton(props: { onClick?: OnClickButton }) {
    return (
        <button class="center-items h-16 w-12" onClick={e => props.onClick?.(e)}>
            <i class="fas fa-search text-default text-dark-gray-400" />
        </button>
    );
}

function NotificationsTab() {
    const [locale] = useLocale();

    return (
        <A
            href="/notifications"
            aria-label={locale().menu.notifications}
            activeClass="text-primary-500"
            inactiveClass="text-dark-gray-400"
            class="center-items relative h-16 w-12"
        >
            <i class="far fa-bell text-md" />
            <UnreadNotifications />
        </A>
    );
}
