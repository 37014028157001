import {
    Accessor,
    batch,
    createContext,
    createEffect,
    createResource,
    createRoot,
    ParentProps,
    Resource,
    Setter,
    Show,
} from "solid-js";
import { awaitResource, createGlobalSignal, Suspend, useRequiredContext } from "../utils/solidjs";
import { useAuth } from "../modules/auth/authContext";
import { parsedEnv } from "../utils/parsedEnv";

export const exampleSubdomain = "mycompany";

// Used for public execution by QR
const subdomainWhenLoggedOutOnLocalhost = "mycompany";

const SubdomainContext = createContext<[Accessor<string | null>, Setter<string>]>();

/** `subdomain()` will be null if it couldn't be inferred, and we need to ask the user.
 * That happens on mobile and localhost.
 *
 * @example
 * const [subdomain, setSubdomain] = useSubdomain();
 * return (
 *     <Show when={subdomain()} fallback={<Button onClick={setSubdomain("mycompany")} />}>
 *         {subdomain => <div>{subdomain()}.aimmanager.dev</div>}
 *     </Show>
 * );
 * */
export function useSubdomain(): [Accessor<string | null>, Setter<string>] {
    return useRequiredContext(SubdomainContext, "useSubdomain", "SubdomainContext");
}

/** Required to `useSubdomain`. Suspends while inferring the subdomain.
 * The subdomain may be inferred from the URL or from Firebase. */
export function SubdomainProvider(props: ParentProps) {
    const { user } = useAuth();
    createEffect(() => {
        const u = user();
        if (u === undefined) return;
        if (u !== null) {
            setFirebaseSubdomain(inferSubdomainFromFirebase(u.firebaseTenantId));
        } else {
            batch(() => {
                setFirebaseSubdomain(null);
                setUserProvidedSubdomain(undefined);
            });
        }
    });

    return (
        <Show when={inferredSubdomain() !== undefined} fallback={<Suspend />}>
            <SubdomainContext.Provider
                value={[
                    () => inferredSubdomain() as string | null,
                    setUserProvidedSubdomain as Setter<string>,
                ]}
            >
                {props.children}
            </SubdomainContext.Provider>
        </Show>
    );
}

/** e.g. "mycompany". */
export async function getSubdomain(): Promise<string> {
    return await awaitResource(subdomain);
}

export function getWebUrl(subdomain: string): string {
    return parsedEnv.VITE_WEB_URL.replaceAll("{subdomain}", subdomain);
}

let subdomain: Resource<string>;
let inferredSubdomain: Resource<string | null>;

const [firebaseSubdomain, setFirebaseSubdomain] = createGlobalSignal<string | null>();
const [userProvidedSubdomain, setUserProvidedSubdomain] = createGlobalSignal<string>();

createRoot(() => {
    const urlSubdomain = inferSubdomainFromUrl(location.origin);

    [inferredSubdomain] = createResource(
        () => {
            const s = urlSubdomain ?? userProvidedSubdomain() ?? firebaseSubdomain();

            // The subdomain `s` may be null, so wrap it in an object or createResource will treat it as falsy
            return s !== undefined ? { s } : s;
        },
        ({ s }) => s,
    );

    [subdomain] = createResource(
        () => {
            const s = inferredSubdomain();
            return s !== undefined ? { s } : s;
        },
        ({ s }) => s ?? subdomainWhenLoggedOutOnLocalhost,
    );
});

function inferSubdomainFromFirebase(firebaseTenantId: string | null): string | null {
    // "mycompany-5nsfv" --> "mycompany"
    // "stg-mycompany-bzaox" --> "mycompany"
    return firebaseTenantId?.split("-").find(word => !isEnvironmentWord(word)) ?? null;
}

export function inferSubdomainFromUrl(url = location.origin): string | undefined {
    // If URL is "https://www.example.com/", domains === ["www", "example", "com"]
    const domains = new URL(url).hostname.split(".");

    // Check for localhost / 127.0.0.1 or some other variant where the subdomain is not present.
    if (domains.length < 3 || domains[0].match(/^\d+$/)) {
        return undefined;
    }

    // If URL is "https://mycompany-dev.aimmanager.dev/", subdomainWords === ["mycompany", "dev"]
    const subdomainWords = domains[0].split("-");
    /* We agreed with Victor to keep this feature of filtering env words
     * just in case we fail to obtain a TLD != .cc for staging.
     * Update 2024-12-05: We have .org now, maybe we can remove this feature now.
     */
    return subdomainWords.filter(word => !isEnvironmentWord(word)).join("-");
}

function isEnvironmentWord(word: string): boolean {
    return (
        word.length >= 3 &&
        // production is not in this array as it will never be in the URL, and may produce false positives (e.g. "proton")
        ["development", "staging", "uat", "release"].some(env =>
            isNonContiguousSubstring(word, env),
        )
    );
}

/** isNonContiguousSubstring("stg", "staging") === true */
function isNonContiguousSubstring(sub: string, full: string): boolean {
    let subIndex = 0;
    for (let char of full) {
        if (char === sub[subIndex]) subIndex++;
        if (subIndex === sub.length) return true;
    }
    return false;
}
