import type { AuthService } from "./services/auth/interface";
import type { HomeService } from "./services/home/interface";
import type { PersonService } from "./services/person/interface";
import type { NotificationsService } from "./services/notifications/interface";
import type { OrganizationService } from "./services/organization/interface";
import type { WorkflowService } from "./services/workflow/interface";
import type { TaskManagerService } from "./services/task-manager/interface";
import type { AudiencesPaulsenService } from "./services/audiences-paulsen/interface";
import type { ChecklistService } from "./services/checklist/interface";
import type { LogicParserService } from "./services/logic-parser/interface";
import _ from "lodash";
import type { PositionService } from "./services/positions/interface";
import type { AudiencesLuchoService } from "./services/audiences-lucho/interface";
import type { LocationsService } from "./services/locations/interface";
import { Json, ServiceQuery } from "./utils";
import { PermissionsService } from "./services/permissions/interface";
import { ReportsService } from "./services/reports/interface";

/** Abstraction used by the UI to access endpoints while being agnostic of
 * whether the data comes from a mock backend or a real one. Endpoints are
 * split into "services" to ease organization. */
export interface Api {
    audiencesLucho: AudiencesLuchoService;
    audiencesPaulsen: AudiencesPaulsenService;
    auth: AuthService;
    checklist: ChecklistService;
    home: HomeService;
    locations: LocationsService;
    logicParser: LogicParserService;
    notifications: NotificationsService;
    organization: OrganizationService;
    permissions: PermissionsService;
    person: PersonService;
    position: PositionService;
    reports: ReportsService;
    taskManager: TaskManagerService;
    workflow: WorkflowService;
}

const services: { [P in keyof Api]: LazyImplementations<P> } = {
    audiencesLucho: { v3: import("./services/audiences-lucho/implementations/v3") },
    audiencesPaulsen: { v3: import("./services/audiences-paulsen/implementations/v3") },
    auth: {
        mock: import("./services/auth/implementations/mock"),
        v3: import("./services/auth/implementations/v3"),
    },
    checklist: { v3: import("./services/checklist/implementations/v3") },
    home: {
        mock: import("./services/home/implementations/mock"),
        atlas: import("./services/home/implementations/atlas"),
    },
    locations: { v3: import("./services/locations/implementations/v3") },
    logicParser: { v3: import("./services/logic-parser/implementations/v3") },
    notifications: {
        mock: import("./services/notifications/implementations/mock"),
        v3: import("./services/notifications/implementations/v3"),
    },
    organization: { v3: import("./services/organization/implementations/v3") },
    permissions: {
        mock: import("./services/permissions/implementations/mock"),
        v3: import("./services/permissions/implementations/v3"),
    },
    person: { v3: import("./services/person/implementations/v3") },
    position: {
        mock: import("./services/positions/implementations/mock"),
        v3: import("./services/positions/implementations/v3"),
    },
    reports: {
        mock: import("./services/reports/implementations/mock"),
        v3: import("./services/reports/implementations/v3"),
    },
    taskManager: {
        mock: import("./services/task-manager/implementations/mock"),
        v3: import("./services/task-manager/implementations/v3"),
    },
    workflow: { v3: import("./services/workflow/v3") },
};
type LazyImplementations<P extends keyof Api> = Partial<
    Record<string, Promise<{ default: { new (): Api[P] } }>>
>;

let apiSingleton: Api | undefined = undefined;
// This monster ensures each Service is lazy loaded to improve app loading time
export function getApiInstance(): Api {
    // This object is expensive to create, let's use ??= to do it only once
    return (apiSingleton ??= _.mapValues(
        services,
        // For each service, api[serviceName] will be a "proxy service" that will lazy load the service behind the scenes
        <P extends keyof Api>(_impls: any, serviceName: P) => {
            return new Proxy(
                {},
                {
                    // e.g. `api.workflow.listWorkflows` will call get(services.workflow, "listWorkflows")
                    get(_target, endpointName: string & keyof Api[P]) {
                        // Return an "endpoint" object that may be a ServiceQuery or an async function (mutation).
                        // We don't know if the endpoint is a query or a mutation as the service hasn't been loaded yet,
                        // so let's return an object that can act as both cases.

                        // Case 1: async function (e.g. mutations)
                        const endpoint = async (...args: unknown[]) => {
                            const service = await loadService(serviceName);
                            apiSingleton![serviceName] = service; // swap the proxy service with the actual service
                            return await service[endpointName](...args);
                        };

                        // Case 2: ServiceQuery (fetchJson and select)
                        endpoint.fetchJson = async (...args: unknown[]) => {
                            const service = await loadService(serviceName);
                            apiSingleton![serviceName] = service;
                            return await (
                                service[endpointName] as ServiceQuery<any, any>
                            ).fetchJson(...args);
                        };
                        endpoint.select = (json: Json) => {
                            // select can't be async, so let's assume fetchJson (called before select) loaded the service
                            const service = apiSingleton![serviceName];
                            if (!service)
                                throw new Error("endpoint.select called before fetchJson");
                            return (service[endpointName] as ServiceQuery<any, any>).select(json);
                        };

                        return endpoint;
                    },
                },
            );
        },
    ) as unknown as Api);
}

type Service = Record<string, ServiceQuery<any, any> | ((...args: unknown[]) => Promise<unknown>)>;
async function loadService<P extends keyof Api>(serviceName: P): Promise<Service & Api[P]> {
    // Happy path
    const envVarName = `VITE_SERVICE_${_.snakeCase(serviceName).toUpperCase()}`;
    // eslint-disable-next-line no-restricted-syntax
    const backendName = window.GARGAMEL_MOCK_BACKEND ? "mock" : import.meta.env[envVarName];
    if (!backendName) throw new Error(`${envVarName} is not defined`);
    const serviceModule = await services[serviceName][backendName];

    // Handle service not found
    if (!serviceModule) {
        const className = `${_.capitalize(
            backendName,
        )}${serviceName[0].toUpperCase()}${serviceName.slice(1)}Service`;
        const error = new Error(
            `Implementation for ${className} not found. Did you add it to src/api/index.ts?`,
        );

        /* If a mock service is not implemented, delay the error until an endpoint is called
         * so we can make tests without having all the mocks.
         */
        if (backendName === "mock")
            return new Proxy(
                {},
                {
                    get() {
                        throw error;
                    },
                },
            ) as Service & Api[P];

        throw error;
    }

    const ServiceImpl = serviceModule.default; // use the default export
    return new ServiceImpl() as Service & Api[P];
}

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        /** If true, ignore VITE_SERVICE_* environment variables,
         * making every service use its mock implementation.
         * Useful for tests that are NOT e2e.
         *
         * @remarks Set this before calling any endpoint!
         */
        GARGAMEL_MOCK_BACKEND?: boolean;
    }
}
