import { JSX, ParentProps } from "solid-js";
import { OnClickDiv } from "../../utils/solidjs";

export function LavandaCard(props: ParentProps & { class?: string; onClick?: OnClickDiv }) {
    return (
        <div
            onClick={e => props.onClick?.(e)}
            class={`rounded-md bg-dark-gray-25 px-4 py-4 sm:px-12 ${props.class ?? ""}`}
        >
            {props.children}
        </div>
    );
}

export function BorderedCard(
    props: ParentProps & {
        class?: string;
        onClick?: () => void;
        style?: JSX.CSSProperties;
        color?: JSX.CSSProperties["color"];
    },
) {
    return (
        <div
            onClick={() => props.onClick?.()}
            class={`rounded-md border border-light-gray-300 p-4 ${props.class || ""} ${
                props.onClick ? "cursor-pointer" : ""
            } ${props.color && "border-l-4"}`}
            style={{
                "border-left-color": props.color,
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
}

export function WireframeCard(props: ParentProps<{ title: JSX.Element }>) {
    return (
        <BorderedCard class="relative">
            <code class="absolute -top-3 left-2 bg-white px-2">{props.title}</code>
            {props.children}
        </BorderedCard>
    );
}
