import { createSignal, For, JSX, Show } from "solid-js";
import { Overlay } from "./SlidingMenu";
import { A } from "@solidjs/router";
import { MenuTab } from "./utils";
import { OnClickButton } from "../../utils/solidjs";
import _ from "lodash";
import { DevSuspense, GenericSuspenseFallback } from "../ui/skeletons";

export default function MobileFooter(props: { tabs: MenuTab[] }) {
    const [expanded, setExpanded] = createSignal(false);
    const numColumns = 4;

    return (
        <Show
            when={expanded()}
            fallback={
                <CollapsedFooter
                    tabs={props.tabs}
                    numColumns={numColumns}
                    onClickPlusButton={() => setExpanded(true)}
                />
            }
        >
            <ExpandedFooter
                tabs={props.tabs}
                numColumns={numColumns}
                onClose={() => setExpanded(false)}
            />
        </Show>
    );
}

const collapsedFooterPx = "0.5rem";
const collapsedFooterPy = "0.25rem";
const plusButtonPx = "0";
const plusButtonPy = "0.5rem";

function CollapsedFooter(props: {
    tabs: MenuTab[];
    numColumns: number;
    onClickPlusButton?: () => void;
}) {
    return (
        <nav
            class="sticky bottom-0 left-0 right-0 flex items-center border-t bg-white text-light-gray-300"
            style={{
                padding: `${collapsedFooterPy} ${collapsedFooterPx}`,
            }}
        >
            <DevSuspense
                fallback={
                    <div class="h-16 w-full text-black">
                        <GenericSuspenseFallback />
                    </div>
                }
            >
                <Show
                    when={props.tabs.length > props.numColumns}
                    fallback={
                        <div class="flex h-full w-full items-center">
                            <For each={props.tabs.slice(0, props.numColumns)}>
                                {tab => <FooterTab {...tab} />}
                            </For>
                        </div>
                    }
                >
                    <div class="flex h-full flex-[2_2_0%] items-center">
                        <For each={props.tabs.slice(0, Math.ceil(props.numColumns / 2))}>
                            {tab => <FooterTab {...tab} />}
                        </For>
                    </div>
                    <div class="center-items flex-1 text-dark-gray-400">
                        <PlusButton onClick={props.onClickPlusButton} />
                    </div>
                    <div class="flex h-full flex-[2_2_0%] items-center">
                        <For
                            each={props.tabs.slice(
                                Math.ceil(props.numColumns / 2),
                                props.numColumns,
                            )}
                        >
                            {tab => <FooterTab {...tab} />}
                        </For>
                    </div>
                </Show>
            </DevSuspense>
        </nav>
    );
}

function ExpandedFooter(props: { tabs: MenuTab[]; numColumns: number; onClose?: () => void }) {
    return (
        <Overlay collapsed={false} onClick={props.onClose}>
            <div class="fixed bottom-0 left-0 right-0">
                <div
                    onClick={event => event.stopPropagation()}
                    class="mx-3 flex flex-wrap rounded-xl bg-white"
                    style={{
                        "margin-bottom": `calc(${collapsedFooterPy} + ${plusButtonPy} + ${diameter} / 2)`,
                    }}
                >
                    <div
                        class="relative grid w-full gap-3 px-3 pb-4 pt-3"
                        style={{
                            "grid-template-columns": `repeat(${props.numColumns}, minmax(0, 1fr))`,
                        }}
                    >
                        {/* Show the tabs like this (numbers are the tab index):
                         *  8   9  10 empty    // "empty" is the empty div `<div />`
                         *  4   5   6   7
                         *  0   1   2   3
                         */}
                        <For each={_.chunk(props.tabs, props.numColumns).reverse()}>
                            {row => (
                                <For each={_.range(props.numColumns).map(i => row[i])}>
                                    {tab => (
                                        <Show when={tab} fallback={<div />}>
                                            <FooterTab {...tab} onClick={props.onClose} />
                                        </Show>
                                    )}
                                </For>
                            )}
                        </For>
                        <div class="absolute bottom-0 left-1/2">
                            <div class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                                <CloseButton onClick={props.onClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}
const diameter = "2.5rem";

function PlusButton(props: { onClick?: OnClickButton }) {
    return (
        <button
            class="center-items h-full w-full"
            style={{ padding: `${plusButtonPy} ${plusButtonPx}` }}
            onClick={e => props.onClick?.(e)}
        >
            <div
                class="center-items border-dark-gray-400"
                style={makeCircleWithOutline(diameter, "1px")}
            >
                <i class="fas fa-plus" />
            </div>
        </button>
    );
}

function CloseButton(props: { onClick?: OnClickButton }) {
    return (
        <button
            class="center-items border-dark-gray-500 bg-white"
            style={makeCircleWithOutline(diameter, "4px")}
            onClick={e => props.onClick?.(e)}
        >
            <i class="fas fa-times text-dark-gray-400" />
        </button>
    );
}

function makeCircleWithOutline(
    diameter: JSX.CSSProperties["width"],
    outlineWidth: JSX.CSSProperties["outline-width"],
) {
    // This is the simplest workaround that renders correctly in Safari (tested in 2023)
    return {
        // Use border because outline is broken in Safari
        "border-width": outlineWidth,
        "border-style": "solid",
        "border-radius": "50%",
        // Make space for the border as it is drawn inside the circle
        width: `calc(${diameter} + ${outlineWidth} * 2)`,
        height: `calc(${diameter} + ${outlineWidth} * 2)`,
        // Workaround so the outline doesn't affect the layout
        margin: `calc(${outlineWidth} * -1)`,
    } satisfies JSX.CSSProperties;
}

function FooterTab(props: MenuTab & { onClick?: () => void }) {
    return (
        <A
            class="center-items h-16 min-w-0 flex-1 flex-col gap-1"
            activeClass="text-primary-500"
            inactiveClass="text-dark-gray-400"
            href={props.href}
            replace
            onClick={props.onClick}
        >
            {/* Splitting the tab in two halves (icon and text) keeps the icons vertically
             * aligned even if their texts use a different number of lines...
             * Unless the title is *very* long, in that case the flex-1 will make the
             * second half to grow so the text fits, sacrificing icon alignment. */}
            <div class="flex flex-1 items-end">
                <i class={`${props.icon} text-md`} />
            </div>
            <div class="flex-1">
                <div
                    class={`max-w-full text-balance text-center text-xs leading-none ${
                        props.title.length > 50 && "break-all"
                    }`}
                >
                    {props.title}
                </div>
            </div>
        </A>
    );
}
