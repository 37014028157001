import { createSignal, onMount } from "solid-js";
import { createRef } from "../../utils/reactRefs";
import { useLocale } from "../i18n/context";

export function SearchBar(props: {
    variant: "white" | "gray";
    autofocus?: boolean;
    placeholder?: string;
    onChange?: (val: string) => void;
    defaultValue?: string;
    class?: string;
}) {
    const [locale] = useLocale();
    const inputRef = createRef<HTMLInputElement>();
    const [value, setValue] = createSignal<string>(props.defaultValue || "");
    // Do what the autofocus attribute should do, see https://stackoverflow.com/a/27314017
    onMount(() => {
        if (props.autofocus) {
            inputRef.current!.focus();
        }
    });

    const onChange = (
        e: Event & {
            currentTarget: HTMLInputElement;
            target: HTMLInputElement;
        },
    ) => {
        const val = e.target.value;
        setValue(val);
        props.onChange?.(val);
    };
    return (
        <div
            onClick={() => inputRef.current!.focus()}
            class={`flex cursor-text items-center rounded-md border-2 border-primary-700/20 px-4 py-2 ${
                props.class || ""
            }`}
            classList={{
                "bg-white": props.variant === "white",
                "bg-light-gray-100": props.variant === "gray",
            }}
        >
            <i class="fas fa-search me-2 text-light-gray-300" />
            <input
                value={value()}
                onChange={onChange}
                ref={inputRef}
                class="placeholder:dark-3 hide-lastpass w-full bg-inherit"
                placeholder={props.placeholder ?? locale().menu.search}
            />
        </div>
    );
}
