import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";
import JsonStorage from "../../../utils/JsonStorage";
import { sRole } from "./interface";
import { array } from "superstruct";

export function createIsEmailLinkQuery(link: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["isEmailLink", link()],
        queryFn: () => api.auth.isSignInWithEmailLink(link()),
    }));
}

export const rolesStorage = JsonStorage("roles", array(sRole()));

export function createMyRolesQuery() {
    return rolesStorage.useQuery([]);
}
