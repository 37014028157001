import { WorkflowCompletionStatus } from "../../api/services/workflow/interface";
import { KnownElementType } from "../workflows/bpmn";
import type { Priority } from "../../api/services/task-manager/interface";
import _ from "lodash";

export type Locale = LocaleDict & LocaleComputedProperties;

type LocaleDict = {
    name: string;
    codeWithCountry: string;
    firstDayOfWeek: number; // 1 = Monday, 7 = Sunday

    activities: {
        noActivitiesAtAll: string;
        noActivitiesThisDay: string;
        showAvailable: string;
        priorities: Record<Priority, string>;
    };

    auth: {
        welcome: string;
        welcomeTo: string;
        organizationUrl: string;
        forgotOrganizationUrl: string;
        findYourOrganization: string;
        letsFindYourOrganization: string;
        weSuggestUseYourEnterpriseEmail: string;
        continueWithEmail: string;
        continuingWithEmail: string;
        wrongMagicCode: string;
        youAreNotInOrganization: (company: string) => string;
        workspaceStepPlease: string;
        youCanChangeItLater: string;
        noWorkspaces1: string;
        noWorkspaces2: string;
        loginWithGoogle: string;
        or: string;
        email: string;
        emailPlaceholder: string;
        continueWithMagicLink: string;
        continuingWithMagicLink: string;
        magicLinkParagraph: string;
        continueWithPassword: string;
        password: string;
        forgotPassword: string;
        passwordResetEmailSent: string;
        signIn: string;
        signingIn: string;
        passwordRequired: string;
        wrongPassword: string;
        noSignInMethods1: string;
        noSignInMethods2: string;
        notImplementedWidget1: string;
        notImplementedWidget2: string;
        notImplementedWidget3: string;
        sessionExpired: string;
        createInvite: string;
        createInviteDescription: string;
        sendEmail: string;
        validateEmail: string;
        validatingEmail: string;
        emailLinkSent: string;
        continue: string;
        continuing: string;
        myCompanyLink: (companyName: string) => string;
        invalidEmail: string;
        checkYourEmail: string;
        weHaveSentACode_1: string;
        weHaveSentACode_2: string;
        cantFindTheCode: string;
        cantFindTheMagicLink: string;
        checkSpam: string;
        weHaveSentLinkToChangePassword: string;
        weHaveSentMagicLink: string;
        signInWithMagicLink: string;
        signingInWithMagicLink: string;
        resendEmail: string;
        enterNewEmail: string;
        contactSupport: string;
        people: string;
        searchUser: string;
        createPerson: string;
        restartNeeded: (organizationName: string) => string;
        exportPeopleToXLSX: string;
        peopleBulkLoad: string;
        peopleSearchLabel: string;
        organizationNotFound: (organizationName: string) => string;
        organizationNotFoundDescription: string;
    };

    checklists: {
        noSections: string;
        noSteps: string;
        checklistExecutions: string;
        noChecklistExecutions: string;
    };

    forms: {
        requiredError: string;
        select: string;
        optional: string;
        withoutFields: string;
        simulateSubmit: string;
        simulationResult: string;
    };

    menu: {
        navigationRailTabs: {
            home: string;
            tools: string;
            organization: string;
            advanced: string;
            myAccount: string;
        };
        search: string;
        expanded: {
            supermarket: string;
            training: string;
            qaTesting: string;
            complaints: string;
            selfManagementOfPosts: string;
            myReports: string;
        };
        logout: string;
        version: string;
        notifications: string;
        profile: string;
    };

    notifications: {
        title: string;
        noNotifications: string;
        newActivity: string;
        newIncident: string;
        newPost: string;
        newReport: string;
        systemUpdate: string;
        test: string;
        deleteNotification: string;
        noMoreNotifications: string;
        permissionDenied: string;
        optOutWarning: (organizationName: string | undefined) => string;
        enableNotifications: string;
        nativeNotifications: string;
        webNotifications: string;
        markAsRead: string;
        inboxNotificationsSseError: string;
    };

    personalData: {
        welcomeToWorkspace: string;
        weCanHelpYou: string;
        pleaseFillTheForm: string;
        name: string;
        roles: string;
        groups: string;
        identityDocumentType: string;
        identityDocumentTypes: {
            CL_CI: string;
            PASSPORT: string;
            PE_DNI: string;
            CO_CC: string;
            MX_CURP: string;
            MX_RFS: string;
            MX_INE: string;
            MX_NSS: string;
            SV_DUI: string;
        };
        identityDocumentNumber: string;
        rutPlaceholder: string;
        invalidRut: string;
        addAnotherIdentityDocument: string;
        email: string;
        phone: string;
        addAnotherPhone: string;
        photo: string;
        optional: string;
        addFile: string;
        submit: string;
        submitting: string;
        notNow: string;
        editProfile: string;
        changeProfilePicture: string;
        saveChanges: string;
        savingChanges: string;
        personCreated: string;
        emailAlreadyInUse: string;
        sendInvitation: string;
        invitationType: string;
        wherePersonBelongs: string;
        wherePersonBelongsOptions: {
            ownOrganization: (val: string) => string;
            comercialPartner: string;
            contractorBusiness: string;
        };
        selectWorkspace: string;
        selectRoles: string;
        dontFindRole: string;
        tabs: {
            profileTab: string;
            placesTab: string;
            documentsTab: string;
            historyTab: string;
            rolesTab: string;
        };
        administrationData: string;
        businessLine: string;
        searchWorkspace: string;
        searchDocuments: string;
        address: string;
        solicitNewDocument: string;
        actions: string;
    };

    posts: {
        posts: string;
        latestPosts: string;
        fromChannel: string;
        goBack: string;
        publishedIn: string;
    };

    settings: {
        title: string;
        language: string;
        simulateOnboarding: string;
        simulateError: string;
        simulatedError: string;
        createInvite: string;
    };

    sync: {
        title: string;
    };

    taskManagerHome: {
        addPhoto: string;
        camera: {
            promptLabelHeader: string;
            promptLabelCancel: string;
            promptLabelPhoto: {
                android: string;
                ios: string;
            };
            promptLabelPicture: string;
        };
        changePhoto: string;
        uploadPhoto: string;
    };

    utils: {
        goBack: string;
        loading: string;
        monthsFull: string[];
        monthsShort: string[];
        atDate: string;
        of: string;
        unknownError: {
            title: string;
            sorry: string;
            meanwhile: string;
            refreshScreen: string;
            refreshPage: string;
            goHome: string;
            restart: string;
            clearAppData: string;
            tryLater: string;
            thanks: string;
        };
        showTechnicalDetails: string;
        hideTechnicalDetails: string;
        support: {
            contactUsAt: string;
            email: string;
            or: string;
            url: string;
        };
        notAvailableForDemo: string;
        routeNotFound: {
            titleNative: string;
            messageNative: string;
            titleWeb: string;
            messageWeb1: string;
            messageWeb2: string;
            goHome: string;
        };
        confirmDiscardChanges: string;
        confirmExitApp: string;
        seeMore: string;
    };

    workflows: {
        noWorkflows: string;
        noBpmn: string;
        editBpmn: string;
        discardChanges: string;
        saveChanges: string;
        changesSaved: string;
        active: string;
        inactive: string;
        activateWorkflow: string;
        activateWorkflowConfirm: (workflowName: string) => string;
        activatingWorkflow: string;
        executeWorkflow: string;
        executingWorkflow: string;
        cannotExecuteInactiveWorkflow: string;
        cannotEditActiveWorkflow: string;
        useUserTaskInstead: (bpmnElementName: string) => string;
        actionSubmit: string;
        actionSuccess: string;
        executionId: string;
        createdAt: string;
        updatedAt: string;
        initiatedAt: string;
        status: string;
        completionStatuses: Record<WorkflowCompletionStatus, string>;
        name: string;
        createWorkflow: string;
        configure: {
            noMessage: string;
            addMessage: string;
            form: {
                tabTitle: string;
                elementDoesntHaveIt: string;
                add: string;
                edit: string;
                confirmSaveChanges: string;
            };
            message: string;
            expiration: string;
        };
        untitled: (thing: string) => string;
        element: string;
        bpmnElementTypes: Partial<Record<KnownElementType, string>>;
    };
};

type LocaleComputedProperties = {
    /** The days of the week in the order of the locale. For instance, Mexico starts their week on Sunday. */
    weekDays: {
        /** 1 = Monday, 7 = Sunday */
        value: number;
        /** Like Intl.DateTimeFormat({ weekday: "narrow" }), but Wednesday is an X, like this:
         *     L M X J V S D */
        narrowX: string;
    }[];
};

export function makeLocale(locale: LocaleDict): Locale {
    // Use Object.assign instead of spread syntax to avoid copying a large amount of properties
    Object.assign(locale, {
        weekDays: (() => {
            const arbitrarySunday = Date.UTC(2022, 9 - 1, 11);
            const weekDays = _.range(7)
                .map(i => (locale.firstDayOfWeek + i) % 7)
                .map(offset => ({
                    value: 1 + offset,
                    narrowX: Intl.DateTimeFormat(locale.codeWithCountry, {
                        weekday: "narrow",
                        timeZone: "UTC",
                    }).format(
                        // Use ugly Date instead of Temporal.PlainTime to avoid needing Temporal polyfill on initial load
                        new Date(arbitrarySunday + offset * 24 * 60 * 60 * 1000),
                    ),
                }));

            // L M M J V S D --> L M X J V S D
            const i = weekDays.findIndex(
                (_, i) => weekDays[i].narrowX === weekDays[i - 1]?.narrowX,
            );
            if (i !== -1) weekDays[i].narrowX = "X";

            return weekDays;
        })(),
    } satisfies LocaleComputedProperties);
    return locale as Locale;
}
